import { User } from "../entities/User";
import { Profile } from "../entities/User/Profile";

/** Utilisateur transmis au composant métier. */
export type ApplicationModuleUser = {
	/** Identifiant CIM de l'utilisateur. */
	identifiant: number | string;
	/** Login utilisé pour se connecté à 360. */
	login: string;
	/** Nom de l'utilisateur. */
	nom: string;
	/** Prénom de l'utilisateur. */
	prenom: string;
	/** Adresse email de l'utilisateur. */
	email: string;
	/** Profil de l'utilisateur métier connecté. */
	profil: Profile;
}

/**
 * Reformate l'utilisateur donné en paramètre pour le composant métier,
 * en filtrant un peu les informations "sensibles"
 */
export const applicationUserToApplicationModuleUser = (user: User) => {
    if (user) {
        return {
            identifiant: user.identifiant,
            login: user.utilisateur,
            nom: user.nom,
            prenom: user.prenom,
            email: user.email,
            profil: user.profile,
        };
    }

    return null;
}